import {
  InterfaceText,
  List,
  ListItem,
  TextSize
} from 'components/atoms/typography';

import { CarbonIconType } from '@carbon/icons-react';
import { Container } from 'components/atoms/layout/Container';
import React from 'react';

export type ProductFeatureSetProps = Readonly<{
  Icon:
    | CarbonIconType
    | ((props: {
        className?: string;
      }) => React.ReactElement<unknown, never> | null);
  title: string;
  features: string[];
}>;

const ProductFeature: React.FC<ProductFeatureSetProps> = ({
  Icon,
  features,
  title
}) => {
  return (
    <div className="mb-2 pt-2 pb-3">
      <div className="flex items-center space-x-2">
        <Icon className="fill-primary-900" />
        <InterfaceText bold mobileSize={TextSize.LongPrimer}>
          {title}
        </InterfaceText>
      </div>
      <List withoutSpacing className="pt-1">
        {features.map(f => (
          <ListItem key={f} mb="" mobileCompact>
            <InterfaceText size={TextSize.LongPrimer}>{f}</InterfaceText>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

const ProductFeatureSet: React.FC<{ features: ProductFeatureSetProps[] }> = ({
  features
}) => {
  return (
    <div className="bg-primary-200 py-rhythm2">
      <Container className="grid grid-cols-2 justify-center gap-rhythm2 gap-x-rhythm0 sm:gap-x-rhythm2 xl:grid-cols-4">
        {features.map(f => (
          <ProductFeature key={f.title} {...f} />
        ))}
      </Container>
    </div>
  );
};

export default ProductFeatureSet;
