import {
  BodyText,
  ExternalLink,
  Heading,
  InterfaceText,
  List,
  ListItem,
  TextSize
} from 'components/atoms/typography';
import React, { useState } from 'react';

import { AppEventName } from 'lib/events/contracts';
import { Container } from 'components/atoms/layout/Container';
import { NonMaybe } from 'gatsby/types';
import { ProductPageQuery } from 'gatsby/graphqlTypes';
import StaticImage from '@svelte/reactify/StaticImage';
import { Switch } from '@headlessui/react';
import TrackSectionView from 'lib/events/TrackSectionView';
import { usePETALink } from 'gatsby/graphql/useExternalCertifications';

type ProductIngredientsProps = {
  data: NonMaybe<ProductPageQuery['p']>;
};

type FeaturedIngredientProps = {
  ingredient: ProductIngredientsProps['data']['featuredIngredients'][0];
};

const ingredientDisclosures = {
  ecoCert: 'Ecocert Organic certified',
  natural: 'COSMOS NATURAL certified',
  upcycled: 'Upcycled',
  organic: 'Uncertified organic'
};

type DisclosureName = ValueOf<typeof ingredientDisclosures>;

const ingredientDisclosuresNames: DisclosureName[] = [
  ingredientDisclosures.ecoCert,
  ingredientDisclosures.natural,
  ingredientDisclosures.upcycled,
  ingredientDisclosures.organic
];

const disclosureId = (name: DisclosureName): number =>
  ingredientDisclosuresNames.findIndex(id => id === name);

function relevantDisclosures(
  data: NonMaybe<NonMaybe<ProductIngredientsProps['data']['ingredients']>>
) {
  const indexes = data.reduce((acc, item) => {
    const ingredient = item?.ingredient;
    if (ingredient?.co) {
      acc.add(disclosureId(ingredientDisclosures.ecoCert));
    } else if (ingredient?.o) {
      acc.add(disclosureId(ingredientDisclosures.organic));
    }

    if (ingredient?.nat) {
      acc.add(disclosureId(ingredientDisclosures.natural));
    }

    if (ingredient?.up) {
      acc.add(disclosureId(ingredientDisclosures.upcycled));
    }
    return acc;
  }, new Set<number>());

  return indexes;
}

function disclosuresOf(
  ingredient: NonMaybe<
    NonMaybe<ProductIngredientsProps['data']['ingredients']>[0]
  >['ingredient'],
  names: DisclosureName[]
) {
  const findIndex = (name: DisclosureName) => names.findIndex(n => n === name);
  const output: number[] = [];
  if (ingredient?.co) {
    output.push(findIndex(ingredientDisclosures.ecoCert));
  } else if (ingredient?.o) {
    output.push(findIndex(ingredientDisclosures.organic));
  }

  if (ingredient?.nat) {
    output.push(findIndex(ingredientDisclosures.natural));
  }

  if (ingredient?.up) {
    output.push(findIndex(ingredientDisclosures.upcycled));
  }

  return output.sort().map(i => i + 1);
}

const FeaturedIngredient: React.FC<FeaturedIngredientProps> = ({
  ingredient
}) => {
  const imageData = ingredient.image;

  if (!imageData) {
    throw Error('Missing featured ingredient image');
  }

  return (
    <li className="col-span-1 flex items-center rounded-lg bg-pearl-600/60 backdrop-blur-md">
      <div className="h-full w-rhythm6 flex-none">
        <StaticImage
          className="safari-radius-img-fix rounded-l-lg"
          data={imageData}
          height={125}
          width={125}
        />
      </div>

      <InterfaceText className="block w-full py-rhythm0 pr-rhythm1 text-right">
        {ingredient?.name}
      </InterfaceText>
    </li>
  );
};

const IngredientsList: React.FC<
  ProductIngredientsProps & {
    showIncis: boolean;
    disclosureNames: DisclosureName[];
  }
> = ({ disclosureNames, data, showIncis }) => {
  if (showIncis) {
    const list = data.incis?.map(item => item?.inci?.name.trim()).join(', ');
    return (
      <BodyText color="" size={TextSize.LongPrimer} className="my-rhythm1">
        {list}
      </BodyText>
    );
  } else {
    const list = data.ingredients?.map(item => {
      const ingredient = item?.ingredient;
      const disclosures = disclosuresOf(ingredient, disclosureNames);
      // const ingredientUrl = useStore(
      //   useIngredientPath(ingredient?.pageMetadata?.slug || '')
      // );
      const name = ingredient?.name.trim();
      return (
        <ListItem compact key={name}>
          {/* <InternalLink dark to={ingredientUrl}> */}
          <BodyText as="span" color="" size={TextSize.LongPrimer}>
            {name}{' '}
            {disclosures.map((d, i) => (
              <sup key={d} className="ml-0.25">
                {d}
                {disclosures.length > 1 && i < disclosures.length - 1
                  ? ', '
                  : ''}
              </sup>
            ))}
          </BodyText>
          {/* </InternalLink> */}
        </ListItem>
      );
    });
    return <List className="gap-10 md:columns-2">{list}</List>;
  }
};

export const ProductIngredients: React.FC<ProductIngredientsProps> = ({
  data
}) => {
  const [showIncis, setShowIncis] = useState(false);
  const featuredIngredients = data.featuredIngredients || [];
  const petaLink = usePETALink();
  const disclosures = Array.from(
    relevantDisclosures(data.ingredients || [])
  ).map(i => ingredientDisclosuresNames[i]);

  if (!data.ingredients?.length) {
    return null;
  }

  return (
    <section className="relative mb-rhythm3 block bg-primary">
      {/* <div className="relative z-10 w-full bg-pearl-600/40 py-rhythm3 text-center backdrop-blur-md 2xl:w-3/4 2xl:rounded-br-sm 2xl:px-12 2xl:text-left">
        <Heading withoutSpacing level={2}>
          Featuring
        </Heading>
      </div> */}
      {/* <div className="w-full py-rhythm4">
        <Heading withoutSpacing level={2} color="text-secondary">
          Featuring
        </Heading>
      </div> */}
      <Container>
        <div className="w-full grid-cols-12 grid-rows-1 gap-rhythm2 py-rhythm3 lg:py-rhythm5 xl:grid">
          {/* Featured Ingredients */}
          <div className="mb-rhythm2 xl:col-span-5 xl:mb-0">
            <div className="w-full pb-rhythm3">
              <Heading withoutSpacing level={2} color="text-secondary">
                Featuring
              </Heading>
            </div>
            <ul className="grid grid-cols-1 justify-center gap-rhythm0 md:grid-cols-2 md:gap-rhythm2 lg:col-span-4 xl:col-span-3 xl:grid-cols-1">
              {featuredIngredients.map(
                ingredient =>
                  ingredient && (
                    <FeaturedIngredient
                      key={ingredient.name}
                      ingredient={ingredient}
                    />
                  )
              )}
            </ul>
          </div>

          <div className="flex flex-col justify-center rounded-sm bg-pearl-600/60 p-rhythm0 py-rhythm2 backdrop-blur-md md:p-rhythm3 xl:col-span-7">
            {/* Full List */}
            <div className="align-center flex flex-col justify-between space-y-3 py-rhythm-1 2xl:flex-row 2xl:space-y-0 2xl:py-rhythm1">
              <Heading
                color="text-primary"
                level={3}
                size={TextSize.GreatPrimer}
                withoutSpacing
              >
                The Honest Full List
              </Heading>

              <div className="flex items-center gap-2">
                <InterfaceText
                  size={TextSize.Brevier}
                  measure=""
                  bold={!showIncis}
                  className="leading-tight"
                >
                  In Plain English
                </InterfaceText>
                <div className="flex">
                  <Switch
                    checked={showIncis}
                    onChange={setShowIncis}
                    className={`${showIncis ? 'bg-primary-200' : 'bg-primary'}
           relative inline-flex h-[33px] w-[79px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-pearl-500 focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={`${
                        showIncis ? 'translate-x-[45px]' : 'translate-x-0'
                      }
            pointer-events-none inline-block h-[29px] w-[29px] rounded-full bg-pearl-500 shadow-lg ring-0 transition duration-200 ease-in-out transform`}
                    />
                  </Switch>
                </div>

                <BodyText
                  size={TextSize.Brevier}
                  measure=""
                  bold={showIncis}
                  className="leading-tight"
                >
                  Scientific INCIs
                </BodyText>
              </div>
            </div>

            <IngredientsList
              disclosureNames={disclosures}
              data={data}
              showIncis={showIncis}
            />
            <BodyText emphasis size={TextSize.LongPrimer}>
              All of our products are <b>vegan</b> and <b>cruelty-free</b>, as
              certified by <ExternalLink url={petaLink}>PETA</ExternalLink>, as
              well as <b>palm-oil free</b>.
            </BodyText>
            {!showIncis &&
              disclosures.map((name, i) => (
                <BodyText
                  key={name}
                  withoutSpacing
                  emphasis
                  size={TextSize.LongPrimer}
                >
                  <sup>{i + 1}</sup> {name}
                </BodyText>
              ))}
          </div>
        </div>
      </Container>

      <TrackSectionView
        eventFactory={() => ({
          name: AppEventName.SawHonestList,
          payload: { productId: data.id }
        })}
      />
    </section>
  );
};
