import { AggregateRating, Product } from 'schema-dts';

type ProductReviewRequestProps = {
  sku: number;
  pathname: string;
};

export async function getProductReviewAggregate({
  sku,
  pathname
}: ProductReviewRequestProps): Promise<AggregateRating | null> {
  const origin = 'https://disruptor.london';
  const url = [
    'https://api.reviews.co.uk/json-ld/product/richsnippet?sku=',
    sku,
    '&store=disruptor.london&url=',
    origin,
    '/uk',
    pathname,
    '&data=undefined'
  ];
  const res = await fetch(url.join(''), {
    headers: { Origin: origin }
  });

  /**
   * Reviews.io API returns content-type "text/html" so headers are not very reliable
   * And if there are no reviews, body is empty
   * This workaround is more resilient than res.json()
   */
  const body = await res.text();
  if (!body) {
    return null;
  }
  const data: Product = JSON.parse(body);
  return (data?.aggregateRating as AggregateRating) || null;
}

export type RatingData = {
  rating: string;
  totalReviews: number;
};

const formatter = new Intl.NumberFormat('en-GB', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
});

export async function getProductReviewData(
  props: ProductReviewRequestProps
): Promise<RatingData | null> {
  const data = await getProductReviewAggregate(props);

  if (!data || data['@type'] !== 'AggregateRating') {
    return null;
  }

  const { ratingValue, reviewCount } = data;

  if (!ratingValue || !reviewCount) {
    return null;
  }

  return {
    rating: formatter.format(+ratingValue),
    totalReviews: +reviewCount
  };
}
