import StarFilled from "@carbon/icons-react/es/star--filled/24";
import StarHalfFilled from "@carbon/icons-react/es/star--half/24";
import StarIcon from "@carbon/icons-react/es/star/24";

import {
	ExternalLink,
	InterfaceText,
	TextSize,
} from "components/atoms/typography";
import { loggers } from "lib/log";
// biome-ignore lint/style/useImportType: <explanation>
import React, { useEffect, useState } from "react";

import { getProductReviewData, type RatingData } from "./api";

const log = loggers.ui;

const Star: React.FC<{ value: number; rating: number }> = ({
	value,
	rating,
}) => {
	if (value < Math.floor(rating)) {
		return <StarFilled className="h-rhythm-1 fill-secondary" />;
	}

	if (value < Math.ceil(rating)) {
		return (
			<span className="relative">
				<StarHalfFilled className="h-rhythm-1 fill-secondary" />
				<StarIcon className="absolute inset-0 h-rhythm-1 fill-secondary" />
			</span>
		);
	}

	return <StarIcon className="h-rhythm-1 fill-secondary" />;
};

type ReviewsIoProductInlineProps = {
	sku: number;
	preloadedData?: RatingData | null;
};

const ReviewsIoProductInline: React.FC<ReviewsIoProductInlineProps> = ({
	sku,
	preloadedData,
}) => {
	console.log("sku: ", sku);
	console.log("preloadedData: ", preloadedData);
	const [data, setData] = useState<RatingData | null | undefined>(
		preloadedData,
	);

	useEffect(() => {
		const { pathname } = location;
		getProductReviewData({
			sku,
			pathname,
		})
			.then(setData)
			.catch((error) => {
				log.error(new Error("Unable to get product review rating"), { error });
			});
	}, [sku]);

	if (!data) {
		return null;
	}

	return (
		<>
			<div className="flex items-center space-x-2">
				<div className="-ml-0.5 flex items-center -space-x-0.5">
					{[0, 1, 2, 3, 4].map((rating) => (
						<Star
							key={rating}
							value={rating}
							rating={+data.rating}
							aria-hidden="true"
						/>
					))}
					<InterfaceText size={TextSize.LongPrimer} className="pl-0.5">
						({data.rating}
						<span className="sr-only"> out of 5 stars</span>)
					</InterfaceText>
				</div>
				<img
					src="/icons/reviewsio-logo.svg"
					alt="Review IO logo"
					className="w-22"
					loading="lazy"
					decoding="async"
				/>
			</div>
			<InterfaceText className="block" size={TextSize.LongPrimer}>
				<ExternalLink
					url={`https://www.reviews.co.uk/product-reviews/store/disruptor.london/${sku}`}
					selfTarget
				>
					Based on {data.totalReviews} verified reviews
				</ExternalLink>
			</InterfaceText>
		</>
	);
};

export default ReviewsIoProductInline;
