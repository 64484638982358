import { makeClassNames } from 'lib/util';

/**
 * Without width, :before will force horizontal scroll bar to show in small viewports
 * which causes the bottom nav bar to overflow and hide partially
 *
 * But then, 100vw also causes horizontal scroll bar to show (smaller than before but it's still there)
 * when there's a vertical scrollbar too
 *
 * TECHNIQUE 1:
 * - sets the width of pseudo element
 * - adjusts viewport
 *
 * This is good for backgrounds
 * See: https://stackoverflow.com/questions/54741506/stretching-before-element-to-width-of-screen
 *
 *
 * TECHNIQUE 2:
 * See: https://css-tricks.com/full-width-containers-limited-width-parents/
 * Uses margins and width to set container
 * Best suited for images
 *
 * Both techniques require for ancestor to be `overflow-x: hidden`
 */
export const fullWidthClassName = 'overflow-x-hidden';

export const beforeFullWidthClassNames = makeClassNames([
  'before:w-screen',
  'before:h-full',
  'before:z-0',
  'before:absolute',
  'before:left-[calc(-50vw+50%)]'
]);

export const fullWidthContentClassNames = makeClassNames([
  'w-screen',
  'relative',
  'left-1/2',
  'right-1/2'
  // TODO: following two
  // margin-left: -50vw;
  // margin-right: -50vw;
]);
