import ProductFeatureSet, {
  ProductFeatureSetProps
} from 'components/molecules/product/Features';

import EarthIcon from '@carbon/icons-react/es/earth--filled/24';
import ErrorIcon from '@carbon/icons-react/es/error/24';
import ListCheckIcon from '@carbon/icons-react/es/list--checked/24';
import MicroscopeIcon from '@carbon/icons-react/es/microscope/24';
import { ProductPageQuery } from 'gatsby/graphqlTypes';
import React from 'react';

type ProductFeaturesData = {
  formulaFeatures?: string[];
  freeFrom?: string[];
  functions?: string[];
  peopleAndPlanetFeatures?: string[];
};

const productFeatures = ({
  formulaFeatures = [],
  functions = [],
  freeFrom = [],
  peopleAndPlanetFeatures = []
}: ProductFeaturesData): ProductFeatureSetProps[] => [
  {
    Icon: ListCheckIcon,
    title: 'Multi-Tasking',
    features: functions
  },
  {
    Icon: ErrorIcon,
    title: 'Free From',
    features: freeFrom
  },
  {
    Icon: MicroscopeIcon,
    title: 'Active-Rich formula',
    features: formulaFeatures
  },
  {
    Icon: EarthIcon,
    title: 'People & Planet',
    features: peopleAndPlanetFeatures
  }
];

type ProductFeaturesSectionProps = Readonly<{
  product: NonNullable<ProductPageQuery['p']>;
}>;

const ProductFeaturesSection: React.FC<ProductFeaturesSectionProps> = ({
  product
}) => {
  const {
    // upcycled,
    functions,
    freeFrom,
    formulaFeatures,
    peopleAndPlanetFeatures
  } = product;

  if (
    !peopleAndPlanetFeatures?.length ||
    !formulaFeatures?.length ||
    !functions?.length ||
    !freeFrom?.length
  ) {
    return null;
  }

  const data = productFeatures({
    peopleAndPlanetFeatures: peopleAndPlanetFeatures?.map(f => f?.name || ''),
    formulaFeatures: formulaFeatures?.map(f => f?.name || ''),
    functions: functions?.map(f => f?.name || ''),
    freeFrom: freeFrom?.map(x => x?.name || '')
  });

  return <ProductFeatureSet features={data} />;
};

export default ProductFeaturesSection;
