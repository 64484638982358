import { useEffect } from 'react';
import { ReviewsIoStatus, reviewsIoProductStatusStore } from './stores';
import { useWritable } from 'lib/react-svelte/reactifyStores';

export default function useProductReviewsIo() {
  const [reviewsStatus, setReviewsStatus] = useWritable(
    reviewsIoProductStatusStore
  );

  function loadReviewsIo() {
    const t = 'script';
    const g = document.createElement(t);
    const s = document.getElementsByTagName(t)[0];
    g.src = 'https://widget.reviews.co.uk/polaris/build.js';
    g.defer = true;
    g.async = true;
    if (s) {
      s.parentNode?.insertBefore(g, s);
      g.onload = function () {
        setReviewsStatus(ReviewsIoStatus.LOADED);
      };
    }
  }

  useEffect(() => {
    if (reviewsStatus === ReviewsIoStatus.LOADED) {
      return;
    }

    if (reviewsStatus === ReviewsIoStatus.INIT) {
      loadReviewsIo();
      setReviewsStatus(ReviewsIoStatus.LOADING);
    }

    const checkLoaded = setInterval(() => {
      if (typeof window.ReviewsWidget !== 'undefined') {
        setReviewsStatus(ReviewsIoStatus.LOADED);
        clearInterval(checkLoaded);
      }
    }, 500);
  }, []);
}
