import type * as GqlSchemaWorker from '@svelte/service/backend/graphql/gql';

import { useGraphqlWorker } from 'state/context/GraphqlWorkerProvider';
import { useQuery } from '@svelte/reactify/useQuery';

function useAllProducts() {
  const worker = useGraphqlWorker();

  return useQuery({
    key: 'allProducts',
    variables: {},
    worker
  });
}

export function useProductFragment(
  productId?: number
): GqlSchemaWorker.ProductFragment | undefined {
  const { data } = useAllProducts();

  if (!productId) {
    return undefined;
  }

  return data && data.allProducts.find(item => item.id === productId);
}
