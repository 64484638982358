import { CdnImageDataFragment } from 'gatsby/graphqlTypes';
import { Container } from 'components/atoms/layout/Container';
import { Heading } from 'components/atoms/typography';
import React from 'react';
import ResponsiveImage from '@svelte/reactify/ResponsiveImage';
import { makeClassNames } from 'lib/util';

type Props = PropsWithChildren &
  PropsWithClassName & {
    // DETOX doesn't have How To Use image
    image?: CdnImageDataFragment;
    heading: string;
  };

export const FeatureBanner: React.FC<Props> = ({
  className,
  heading,
  image,
  children
}) => {
  return (
    <div className={makeClassNames(className, image && "grid-cols-2 2xl:grid", 'w-full')}>
      {!!image && <ResponsiveImage height="h-rhythm11 2xl:h-auto" data={image} />}
      <div className="flex items-center pb-rhythm3">
        <Container narrow>
          <Heading className="py-rhythm3" level={2} withoutSpacing>
            {heading}
          </Heading>
          {children}
        </Container>
      </div>
    </div>
  );
};
