import {
  CdnImageDataFragment,
  ProductPageQuery,
  StrapiProductCardFragment
} from '../gatsby/graphqlTypes';
import {
  ProductHeader,
  ProductIngredients
} from 'components/molecules/product';
import React, { useEffect } from 'react';

import { AppEventName } from 'lib/events/contracts';
import { AppPageProps } from 'gatsby/types';
import { Container } from 'components/atoms/layout/Container';
import ExternalCertificationsBanner from 'components/molecules/banners/certifications/CertificationsBanner';
import { FeatureBanner } from 'components/molecules/banners/Feature';
import FeaturedQuote from 'components/molecules/cms/FeaturedQuote';
import { Heading } from 'components/atoms/typography';
import PageContentLayout from 'components/layouts/PageContentLayout';
import ProductFeatureSection from 'components/organisms/product/FeaturesSection';
import { ProductList } from 'components/organisms/product-list/List';
import ReviewsIoWidget from 'lib/reviews/ProductReviewsIo';
import SlateContent from 'components/molecules/cms/SlateContent';
import TrackSectionView from 'lib/events/TrackSectionView';
import { currentProductPageData } from 'state/stores/nav';
import { graphql } from 'gatsby';
import { useWritable } from 'lib/react-svelte/reactifyStores';

export { Head } from 'components/atoms/Head';

export const query = graphql`
  query ProductPage($strapiId: Int!) {
    p: strapiProduct(strapiId: { eq: $strapiId }) {
      bannerMedia {
        ... on StrapiComponentMediaImage {
          __typename
          image {
            ...CdnImageData
          }
        }
        ... on StrapiComponentMediaImageWithOrientation {
          __typename
          landscape {
            ...CdnImageData
          }
          portrait {
            ...CdnImageData
          }
        }
        ... on StrapiComponentMediaResponsiveVideo {
          ...ResponsiveVideo
          __typename
        }
      }
      desc: longDescriptionSlate {
        ...SlateContent
      }
      descriptor
      familyDescriptor
      featuredIngredients {
        name
        pageMetadata {
          slug
        }
        image {
          ...CdnImageData
        }
      }
      howToUse {
        instructions: instructionsSlate {
          ...SlateContent
        }
        title
        media {
          ...CdnImageData
        }
      }
      id: strapiId
      incis {
        inci {
          name
        }
      }
      ingredients {
        ingredient {
          name
          o: organic
          co: cosmosOrganicCertified
          nat: cosmosNaturalCertified
          up: upcycled
          pof: palmOilFree
          pageMetadata {
            slug
          }
        }
      }
      # ingredientsImage {
      #   alternativeText
      #   file {
      #     publicURL
      #     childImageSharp {
      #       gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.78, width: 1280)
      #     }
      #   }
      # }
      inspirationalQuote {
        content
        author
        backgroundColour
        textColour
        containerColour
      }
      name
      functions {
        name
        shortName
      }
      freeFrom {
        name
      }
      formulaFeatures {
        name
      }
      maxPrice
      minPrice
      pageMetadata {
        slug
      }
      related: relatedProducts {
        products {
          ...StrapiProductCard
        }
        title
      }
      shortDescription
      skus {
        ...StrapiSku
      }
      review {
        rating
        totalReviews
      }
      peopleAndPlanetFeatures {
        name
        proofUrl
      }
      upcycled
    }
  }
`;

type Props = AppPageProps<ProductPageQuery>;

const ProductPage: React.FC<Props> = ({ data, pageContext }) => {
  const [, updateProductPageData] = useWritable(currentProductPageData);
  const product = data.p;
  if (!product) {
    throw Error('Missing product data');
  }

  const { desc, howToUse, inspirationalQuote, related } = product;

  useEffect(() => {
    updateProductPageData(product);
    () => updateProductPageData(null);
  }, [product.id]);

  return (
    <PageContentLayout footerNavigation={pageContext.footerNavigation}>
      <ProductHeader product={product} />
      <ProductFeatureSection product={product} />
      <ProductIngredients data={product} />
      {/* <ProductFunctions data={productData} /> */}
      <Container className="relative flex justify-center pb-rhythm3">
        <SlateContent content={desc} className="pt-rhythm1" />
      </Container>
      <TrackSectionView
        eventFactory={() => ({
          name: AppEventName.ReadFullProductDescription,
          payload: { productId: product.id }
        })}
      />
      <Container className="py-rhythm2">
        <ExternalCertificationsBanner />
      </Container>
      {!!howToUse && (
        <FeatureBanner
          image={howToUse.media as CdnImageDataFragment}
          heading={howToUse.title}
          className="bg-primary-100"
        >
          <SlateContent content={howToUse.instructions} />
          <TrackSectionView
            eventFactory={() => ({
              name: AppEventName.SawProductHowTo,
              payload: { productId: product.id }
            })}
          />
        </FeatureBanner>
      )}
      <ReviewsIoWidget sku={product.id} />
      <TrackSectionView
        eventFactory={() => ({
          name: AppEventName.SawProductReviews,
          payload: { productId: product.id }
        })}
      />
      <FeaturedQuote data={inspirationalQuote} />
      {!!related && (
        <Container as="section" className="pb-rhythm4">
          <Heading level={2}>
            {related.title || 'You may also be interested in'}
          </Heading>
          <ProductList
            cardSize="small"
            products={related.products as StrapiProductCardFragment[]}
          />
          <TrackSectionView
            eventFactory={() => ({
              name: AppEventName.SawRelatedProducts,
              payload: { productId: product.id }
            })}
          />
        </Container>
      )}
    </PageContentLayout>
  );
};

export default ProductPage;
