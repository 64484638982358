import {
  BodyText,
  Heading,
  InterfaceText,
  TextCase,
  TextSize
} from 'components/atoms/typography';
import HeroMedia, { HeroMediaItem } from './HeroMedia';
import { ProductPageQuery, StrapiSkuFragment } from 'gatsby/graphqlTypes';
import React, { useEffect } from 'react';
import {
  SkuSelectionError,
  setSkuSelection,
  skuSelectionErrorStore,
  useProductSkuSelection
} from 'state/stores/product';
import { deductAmount, priceToString } from 'lib/l10n';
import { invoicedDiscountStore, useAddToCart } from 'state/stores/checkout';
import { useReadable, useWritable } from 'lib/react-svelte/reactifyStores';

import Balancer from 'react-wrap-balancer';
import { Container } from 'components/atoms/layout/Container';
import DeliveryIcon from '@carbon/icons-react/es/delivery/24';
import { DiscountTag } from '@svelte/reactify/DiscountTag';
import FormattedPrice from './FormattedPrice';
import GiftIcon from '@carbon/icons-react/es/gift/24';
import ReviewsIoProductInline from 'lib/reviews/ProductRatingInline';
import SkuSelect from './SkuSelect';
import { TextButton } from 'components/atoms/button';
import { makeClassNames } from 'lib/util';
import { useProductFragment } from 'service/hooks/product';

type AddToCartProps = {
  inStock: boolean;
  productId: number;
};

const AddToCartButton: React.FC<AddToCartProps> = ({ inStock, productId }) => {
  const discount = useReadable(invoicedDiscountStore);
  const selectedSku = useProductSkuSelection(productId);
  const addToCart = useAddToCart();
  const [skuSelectionError, setSkuError] = useWritable(skuSelectionErrorStore);
  const cartProduct = useProductFragment(productId);

  const handleAddToCart = () => {
    if (!selectedSku || !cartProduct) {
      setSkuError({
        error: !selectedSku
          ? SkuSelectionError.NoWeightSelected
          : SkuSelectionError.NoDataFound,
        notification: true
      });
      return;
    }

    const skuData = cartProduct.skus.find(s => s.id == selectedSku.id);
    skuData &&
      addToCart({
        ...cartProduct,
        __typename: 'CartProduct',
        skus: [
          {
            __typename: 'CartSku',
            qty: 1,
            data: skuData
          }
        ]
      });
  };

  useEffect(() => {
    /**
     * Once there is a sku selected there
     * shouldn't be any error.
     * This clears it
     */
    if (
      selectedSku &&
      skuSelectionError.error === SkuSelectionError.NoWeightSelected
    ) {
      setSkuError({
        error: SkuSelectionError.None,
        notification: false
      });
    }
  }, [selectedSku]);

  if (!inStock) {
    return (
      <TextButton disabled className="my-rhythm1 w-full">
        Out of Stock
      </TextButton>
    );
  }

  const price =
    selectedSku &&
    priceToString(deductAmount(selectedSku.priceInt, discount?.amount));

  return (
    <>
      <TextButton
        onClick={handleAddToCart}
        className={makeClassNames(
          'my-rhythm1',
          // this is roughly equivalent measure-wide to match text
          'max-w-[808px]',
          !selectedSku && 'cursor-not-allowed'
        )}
      >
        Add to Cart{price && ` - ${price}`}
      </TextButton>
    </>
  );
};

const StockInformation: React.FC<{ inStock: boolean }> = ({ inStock }) => {
  return (
    <>
      <span className="relative flex h-2 w-2 justify-self-center">
        {inStock && (
          <span
            className={makeClassNames(
              'absolute inline-flex h-full w-full animate-ping-slow rounded-full bg-emerald-500 opacity-75 duration-700'
            )}
          />
        )}
        <span
          className={makeClassNames(
            'relative inline-flex h-2 w-2 rounded-full',
            inStock ? 'bg-emerald-600' : 'bg-pearl-900'
          )}
        ></span>
      </span>
      <BodyText withoutSpacing size={TextSize.LongPrimer}>
        {inStock ? 'In ' : 'Out of '}stock
      </BodyText>
    </>
  );
};

type ProductHeaderProps = {
  product: NonNullable<ProductPageQuery['p']>;
};

export const ProductHeader: React.FC<ProductHeaderProps> = ({ product }) => {
  const {
    descriptor,
    name,
    shortDescription,
    familyDescriptor,
    id: productId,
    maxPrice,
    minPrice
  } = product;
  const skus = ((product.skus || []) as StrapiSkuFragment[])
    .slice()
    .sort((a, b) => a.weight - b.weight);

  const atLeastOneSkuInStock = skus.some(sku => sku.inStock);

  if (!productId || !skus.length) {
    throw new Error('Unable to render product header');
  }

  useEffect(() => {
    if (skus.length === 1) {
      setSkuSelection(productId, skus[0]);
    }
  }, []);

  return (
    <>
      <HeroMedia
        media={(product.bannerMedia || []) as HeroMediaItem[]}
        className="xl:hidden"
      />
      <Container
        as="header"
        className="pb-rhythm2 xl:grid xl:grid-cols-12 xl:grid-rows-1 xl:gap-x-rhythm5"
      >
        <div className="hidden xl:col-span-6 xl:flex xl:h-full xl:flex-col xl:justify-center">
          <HeroMedia media={(product.bannerMedia || []) as HeroMediaItem[]} />
        </div>

        {/* Product details */}
        <div className="xl:col-span-6">
          <div className="mb-rhythm2 mt-rhythm2 xl:mb-0">
            <Heading level={2} size={TextSize.Trafalgar} bold withoutSpacing>
              {name}
            </Heading>
            <Heading
              level={1}
              size={TextSize.Pica}
              withoutSpacing
              measure="measure-wide"
            >
              <Balancer>{familyDescriptor}</Balancer>
            </Heading>

            <InterfaceText
              size={TextSize.Pica}
              className="mt-rhythm-3 block"
              color="text-primary"
            >
              {minPrice !== maxPrice && (
                <span className="mr-1 text-xs">From</span>
              )}
              <FormattedPrice priceIntegerWithDecimals={minPrice} />
            </InterfaceText>
            <DiscountTag className="mt-1" />

            <div className="my-rhythm1">
              <ReviewsIoProductInline
                sku={productId}
                preloadedData={product.review}
              />
            </div>

            {/* Short description */}
            <Heading
              level={3}
              textCase={TextCase.None}
              size={TextSize.BodyCopy}
              withoutSpacing
              className="mb-rhythm-3"
              bold
              measure="measure-wide"
            >
              <Balancer>{descriptor}</Balancer>
            </Heading>
            <BodyText size={TextSize.LongPrimer}>{shortDescription}</BodyText>

            <SkuSelect productId={productId} skus={skus} />

            <AddToCartButton
              inStock={atLeastOneSkuInStock}
              productId={productId}
            />

            <div className="my-rhythm1 grid grid-cols-[26px_auto] grid-rows-3 items-center gap-x-1">
              <StockInformation inStock={atLeastOneSkuInStock} />

              <GiftIcon className="w-4 justify-self-center fill-primary-900" />
              <BodyText withoutSpacing size={TextSize.LongPrimer}>
                Free shipping on orders over £15
              </BodyText>

              <DeliveryIcon className="w-4 justify-self-center fill-primary-900" />
              <BodyText withoutSpacing size={TextSize.LongPrimer}>
                Next-day dispatch before 2PM
              </BodyText>
            </div>

            {/* <ProductDispatchMark inStock={inStock} /> */}
            {/* <div className="mt-rhythm-3 flex items-center text-pearl-700">
                  <Pill
                    size="s"
                    role={PillRole.Neutral}
                  >
                    Time limited offer -{' '}
                    <span className="inline-block">
                      <b>FREE SHIPPING</b> on all orders
                    </span>
                  </Pill>
                </div> */}
          </div>
        </div>
      </Container>
    </>
  );
};
