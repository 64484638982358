import '../../../node_modules/plyr/dist/plyr.css';

import type * as PlyrJS from 'plyr';

import type { DetailedHTMLProps, VideoHTMLAttributes } from 'react';
import React, { useEffect, useRef } from 'react';

import { AppErrorName } from 'lib/events/errors';
import { Breakpoint } from 'lib/device/state';
import { IS_DEVELOPMENT_ENV } from 'lib/util';
import { ResponsiveVideoFragment } from 'gatsby/graphqlTypes';
import { hash } from 'lib/string';
import useAppEventDispatcher from 'lib/events/hooks';
import { useWindowDimensions } from 'lib/hooks/useWindowDimensions';

const loadPlyr = () =>
  import(/* webpackChunkName: "plyr" */ 'plyr').then(x => x.default);

function sourceInfoFromFragment(
  data: ResponsiveVideoFragment
): PlyrJS.SourceInfo {
  const poster = data.poster?.url || undefined;
  const sources: PlyrJS.SourceInfo['sources'] = data.sources
    .filter(Boolean)
    .map(source => {
      const s = source as NonNullable<ResponsiveVideoFragment['sources'][0]>;
      return {
        src: s.file?.url as string,
        type: s.mimeType,
        provider: 'html5',
        size: s.resolution
      };
    });

  return {
    type: 'video',
    title: data.title,
    poster,
    sources
  };
}

type ReactVideoProps = DetailedHTMLProps<
  VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>;
export type PlyrProps = Omit<ReactVideoProps, 'ref'> &
  PropsWithClassName & {
    backgroundColor?: string;
    video: ResponsiveVideoFragment;
    onPlay?: (event: PlyrJS.PlyrEvent) => void;
    play?: boolean;
    onEnd?: (event: PlyrJS.PlyrEvent) => void;
  };

const Plyr: React.FC<PlyrProps> = ({
  backgroundColor,
  video,
  className,
  onPlay,
  onEnd,
  play
}) => {
  const dimensions = useWindowDimensions();
  const useHd = dimensions.width > Breakpoint.SM;
  const eventDispatcher = useAppEventDispatcher();

  const plyrInstance = useRef<null | PlyrJS>(null);
  const videoId = `vid_${hash(video.title)}`;

  const sourceInfo = sourceInfoFromFragment(video);
  const quality: Array<number> = Array.from(
    video.sources.reduce((acc, s) => {
      if (s) {
        acc.add(s.resolution);
      }
      return acc;
    }, new Set<number>())
  ).sort();

  const plyrCssVars = {
    '--plyr-color-main': '#f19327',
    '--plyr-video-control-color': '#f2f0e9',
    '--plyr-video-control-color-hover': '#f2f0e9'
  } as Record<string, string>;

  if (backgroundColor) {
    plyrCssVars['--plyr-video-background'] = backgroundColor;
  }

  useEffect(() => {
    loadPlyr().then(PlyrJS => {
      const options: PlyrJS.Options = {
        // NOTE: controls have to be in this exact order to take effect
        controls: [
          'play-large', // The large play button in the center
          useHd ? 'restart' : '', // 'restart', // Restart playback
          'rewind', // Rewind by the seek time (default 10 seconds)
          'play', // Play/pause playback
          useHd ? 'fast-forward' : '', // Fast forward by the seek time (default 10 seconds)
          'progress', // The progress bar and scrubber for playback and buffering
          // 'current-time', // The current time of playback
          // 'duration', // The full duration of the media
          'mute', // Toggle mute
          'volume', // Volume control
          // 'captions', // Toggle captions
          'settings', // Settings menu
          // 'pip', // Picture-in-picture (currently Safari only)
          // 'airplay', // Airplay (currently Safari only)
          // 'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
          'fullscreen' // Toggle fullscreen
        ],
        quality: {
          // TODO: 720 plays too small in mobile
          // default: useHd ? 1080 : 720,
          default: quality[0],
          options: quality
        },
        ratio: video.ratio
      };

      // NOTE: passing an element reference doesn't play well when Plyr is recreated
      // const plyr = new PlyrJS(videoRef.current as HTMLVideoElement, options);
      const plyr = new PlyrJS(`#${videoId}`, options);

      plyr.source = sourceInfo;

      {
        onPlay && plyr.on('play', onPlay);
      }
      {
        onEnd && plyr.on('ended', onEnd);
      }

      plyr.on('error', event => {
        const plyr = event.detail.plyr;
        // original video is not documented in typescript
        // It's a reference to the <video> element which Plyr will override
        // A Plyr error won't happen in that element
        // const originalVideo = (plyr.elements as any).original;
        const container = plyr.elements.container;
        const plyrVideoEl =
          container && container.getElementsByTagName('video')[0];
        const plyrVideoError: MediaError | null | undefined =
          plyrVideoEl?.error;

        eventDispatcher.dispatchError(AppErrorName.PlyrError, {
          event,
          mediaError: plyrVideoError,
          sourceInfo
        });
      });
      plyrInstance.current = plyr;

      // NOTE: this may be equivalent to autoplay
      // if play is true on mount
      // However, in this case, rules of autoplay would apply
      if (!plyr.playing && play) {
        plyr.play();
      }
    });

    return () => {
      if (plyrInstance.current) {
        plyrInstance.current.destroy(() => {}, false);
        plyrInstance.current = null;
      }
    };
  }, [useHd]);

  useEffect(() => {
    const plyr = plyrInstance.current;

    if (plyr) {
      if (!plyr.playing && play) {
        plyr.play();
      }

      if (plyr.playing && !play) {
        plyr.pause();
      }
    }
  }, [play]);

  return (
    <div className={className} style={plyrCssVars}>
      <video id={videoId} poster={sourceInfo.poster}>
        {/*
          These are fallback sources which will be overridden by Plyr 
          Native doesn't quite support the media attribute in source element
          in order pick size
        */}
        {video.sources.map(source =>
          source && source.resolution >= 1080 ? (
            <source
              key={source.file?.url}
              src={source.file?.url}
              type={source.mimeType}
            />
          ) : null
        )}
      </video>
    </div>
  );
};

if (IS_DEVELOPMENT_ENV) {
  Plyr.displayName = 'Plyr';
}

export default Plyr;
