import { CdnImageDataFragment, ProductPageQuery } from 'gatsby/graphqlTypes';
import React, { useState } from 'react';

import { AppEventName } from 'lib/events/contracts';
import Chevron from 'components/atoms/icons/Chevron';
import FullWidthImage from '@svelte/reactify/FullWidthImage';
import PlayIcon from '@carbon/icons-react/es/play--outline/24';
import Plyr from 'lib/media/Plyr';
import ResponsiveImage from '@svelte/reactify/ResponsiveImage';
import { makeClassNames } from 'lib/util';
import useAppEventDispatcher from 'lib/events/hooks';

type CarouselMediaProps = {
  active: boolean;
  media: HeroMediaItem;
  eager?: boolean;
};

const CarouselMedia: React.FC<CarouselMediaProps> = ({
  active,
  media,
  eager
}) => {
  if (media.__typename === 'StrapiComponentMediaImage') {
    const image = media.image;

    if (image) {
      // NOTE: gravity center is to fix issues with travel case image
      return <ResponsiveImage data={image} eager={eager} gravity="center" />;
    }
  }

  /**
   * This block is currently only used for art direction
   * Only product to have it is the travel case, which requires different images
   * The props are specially dedicated to those images with no crop and object-contain
   */
  if (media.__typename === 'StrapiComponentMediaImageWithOrientation') {
    const portrait = media.portrait;
    const landscape = media.landscape;

    if (portrait && landscape) {
      return (
        <>
          <FullWidthImage
            display="block sm:hidden"
            eager={eager}
            data={portrait}
            crop="none"
            objectFit="object-contain"
          />
          <FullWidthImage
            display="hidden sm:block xl:hidden"
            eager={eager}
            data={landscape}
            crop="none"
            objectFit="object-contain"
          />
          <ResponsiveImage
            display="hidden xl:block"
            data={portrait}
            eager={eager}
            crop="none"
            objectFit="object-contain"
          />
        </>
      );
    }
  }

  if (media.__typename === 'StrapiComponentMediaResponsiveVideo') {
    return (
      <Plyr
        backgroundColor="var(--pearl-500)"
        video={media}
        className="h-full w-full"
        play={active}
        // onPlay={() =>
        //   eventDispatcher.dispatch(AppEventName.StartedWatchingCompanyVideo)
        // }
        // onEnd={() =>
        //   eventDispatcher.dispatch(AppEventName.FinishedWatchingCompanyVideo)
        // }
      />
    );
  }

  return null;
};

type ThumbnailProps = {
  thumbnail: CdnImageDataFragment;
  lazy?: boolean;
  active: boolean;
  isVideo: boolean;
};

const Thumbnail: React.FC<ThumbnailProps> = ({
  thumbnail,
  lazy,
  active,
  isVideo
}) => {
  return (
    <div
      className={makeClassNames(
        'relative h-full w-rhythm6 2xl:h-rhythm6',
        active && 'border-2 border-primary'
      )}
    >
      {isVideo && (
        <div className="pointer-events-none absolute inset-0 z-10 flex items-center justify-center">
          <PlayIcon className="fill-pearl-300 scale-200" />
        </div>
      )}
      <ResponsiveImage data={thumbnail} eager={!lazy} crop="none" />
    </div>
  );
};

export type HeroMediaItem = NonNullable<
  NonNullable<NonNullable<ProductPageQuery['p']>['bannerMedia']>[0]
>;

type HeroMediaProps = Readonly<{
  media: Array<HeroMediaItem>;
  className?: string;
}>;

const HeroMedia: React.FC<HeroMediaProps> = ({ className, media }) => {
  const [activeImageIndex, setCarouselMediaIndex] = useState(0);
  const activeMediaItem = media[activeImageIndex];
  const eventDispatcher = useAppEventDispatcher();

  const thumbnails = media.map(media => {
    switch (media.__typename) {
      case 'StrapiComponentMediaImage':
        return { isVideo: false, image: media.image };
      case 'StrapiComponentMediaResponsiveVideo':
        return { isVideo: true, image: media.poster };
      case 'StrapiComponentMediaImageWithOrientation':
        return { isVideo: false, image: media.portrait };
      default:
        throw Error('No thumbnail');
    }
  }) as { isVideo: boolean; image: CdnImageDataFragment }[];

  const handler: React.MouseEventHandler<HTMLDivElement> = e => {
    const target = e.target;
    if (target instanceof HTMLImageElement) {
      const index = media.findIndex(media => {
        switch (media.__typename) {
          case 'StrapiComponentMediaImage':
            return media.image?.alt === target.alt;
          case 'StrapiComponentMediaResponsiveVideo':
            return media.poster?.altText === target.alt;
          case 'StrapiComponentMediaImageWithOrientation':
            return media.portrait?.alt === target.alt;
          default:
            return false;
        }
      });
      if (index > -1) {
        setCarouselMediaIndex(index);
        eventDispatcher.dispatch(AppEventName.SawCarouselItem, {
          carouselName: 'product-header',
          index
        });
      }
    }
  };

  if (!activeMediaItem) {
    return null;
  }

  return (
    <div
      className={makeClassNames(
        className,
        'flex h-rhythm12 flex-col 2xl:h-full 2xl:max-h-rhythm12 2xl:flex-row-reverse 2xl:gap-x-rhythm0'
      )}
    >
      <div className="relative flex h-[calc(100%-5.7rem)] flex-1 items-center justify-center 2xl:h-full 2xl:flex-1">
        <div
          className={makeClassNames(
            'absolute bottom-0 left-0 z-10 cursor-pointer p-6',
            activeImageIndex < 1 && 'hidden'
          )}
          onClick={() => setCarouselMediaIndex(activeImageIndex - 1)}
        >
          <Chevron direction="left" className="scale-125" />
        </div>
        {media.map((m, i) => (
          <div
            key={i}
            className={makeClassNames(
              'h-full w-full transition-opacity duration-1000',
              activeImageIndex === i
                ? 'opacity-100'
                : 'invisible absolute opacity-0'
            )}
          >
            <CarouselMedia media={m} active={i === activeImageIndex} />
          </div>
        ))}

        <div
          className={makeClassNames(
            'absolute bottom-0 right-0 z-10 cursor-pointer p-6',
            activeImageIndex >= thumbnails.length - 1 && 'hidden'
          )}
          onClick={() => setCarouselMediaIndex(activeImageIndex + 1)}
        >
          <Chevron direction="right" className="scale-125" />
        </div>
      </div>
      <div
        onClick={handler}
        className="flex h-[5.7rem] w-full cursor-pointer justify-center sm:mt-rhythm1 2xl:h-auto 2xl:w-rhythm6 2xl:flex-col"
      >
        {thumbnails.map((thumbnail, i) => (
          <Thumbnail
            key={i}
            thumbnail={thumbnail.image}
            lazy
            active={i === activeImageIndex}
            isVideo={thumbnail.isVideo}
          />
        ))}
      </div>
    </div>
  );
};

export default HeroMedia;
