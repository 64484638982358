import { TextProps, TextSize } from '@svelte/components/atoms/typography/props';

import React from 'react';
import { makeClassNames } from 'lib/util';

type BlockquoteProps = TextProps & {
  featured?: boolean;
  size?: TextSize;
};

const sizeClassName = (x: TextSize): string[] => {
  switch (x) {
    case TextSize.Pica:
      return ['text-f1'];
    case TextSize.BodyCopy:
      return ['text-f0'];
    default:
      return ['text-f0'];
  }
};

const spacingOfSize = (x: TextSize): string[] => {
  switch (x) {
    default:
      return ['my-rhythm1'];
  }
};

const textClassNames = ['lig'];

const BlockQuote: React.FC<BlockquoteProps> = props => {
  const {
    children,
    className = '',
    color = 'text-primary-900',
    featured = false,
    id,
    measure = 'measure-narrow',
    size = featured ? TextSize.Pica : TextSize.BodyCopy,
    withoutSpacing = false
  } = props;

  const classNameMerged = makeClassNames(
    featured && 'font-title',
    className.split(' '),
    color,
    measure,
    textClassNames,
    sizeClassName(size),
    withoutSpacing ? [] : spacingOfSize(size)
  );

  return (
    <blockquote className={classNameMerged} id={id}>
      {children}
    </blockquote>
  );
};

export default BlockQuote;
