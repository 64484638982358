import { BodyText, TextSize } from 'components/atoms/typography';

import Blockquote from 'components/atoms/typography/Blockquote';
import Quotes32 from '@carbon/icons-react/es/quotes/32';
import React from 'react';

type ContentProps = {
  content: string;
  author: string;
};

const FeaturedQuoteContent: React.FC<ContentProps> = ({ content, author }) => {
  return (
    <>
      <div className="h-22 w-22">
        <Quotes32 className="h-full w-full" />
      </div>

      <Blockquote color="" featured>
        {content}
      </Blockquote>

      <BodyText
        className="text-right"
        as="figcaption"
        color=""
        measure=""
        size={TextSize.LongPrimer}
      >
        —{author}
      </BodyText>
    </>
  );
};

type Props = {
  data?: {
    content: string;
    author: string;
    backgroundColour: string;
    textColour: string;
    containerColour: string;
  } | null;
};

const FeaturedQuote: React.FC<Props> = ({ data }) => {
  if (!data) {
    return null;
  }

  const style = {
    '--q-bg-color': data.backgroundColour,
    '--q-color': data.textColour,
    '--q-container-color': data.containerColour
  } as React.CSSProperties;

  return (
    <>
      {/* MOBILE */}
      {/* NOTE: if you change font text, then edit the validation of contrast in CMS too */}
      <div
        style={style}
        className="flex justify-center bg-[color:var(--q-bg-color)] lg:hidden"
      >
        <figure
          style={style}
          className="px-5 py-rhythm6 text-[color:var(--q-color)] lg:hidden"
        >
          <FeaturedQuoteContent
            content={data.content}
            author={data.author}
          ></FeaturedQuoteContent>
        </figure>
      </div>

      {/* DESKTOP */}
      <div
        style={style}
        className="hidden justify-center bg-[color:var(--q-container-color)] lg:flex"
      >
        <div className="hidden w-1/2 py-rhythm8 text-[color:var(--q-color)] lg:block">
          <div className="relative before:absolute before:-top-1/15 before:left-8 before:z-10 before:h-full before:w-full before:border-8 before:border-[color:var(--q-color)]">
            <figure className="relative z-10 bg-[color:var(--q-bg-color)] p-rhythm2 px-8">
              <FeaturedQuoteContent
                content={data.content}
                author={data.author}
              ></FeaturedQuoteContent>
            </figure>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedQuote;
