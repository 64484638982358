import { IS_DEVELOPMENT_ENV, makeClassNames } from 'lib/util';
import { InterfaceText, TextSize } from 'components/atoms/typography';
import React, { useEffect } from 'react';
import {
  SkuSelectionError,
  setSkuSelection,
  skuSelectionErrorStore,
  useProductSkuSelection
} from 'state/stores/product';

import FormattedPrice from './FormattedPrice';
import ScalesIcon from '@carbon/icons-react/es/scales/24';
import { StrapiSkuFragment } from 'gatsby/graphqlTypes';
import { useWritable } from 'lib/react-svelte/reactifyStores';

const unselectedClassNames = [
  'enabled:border-primary',
  'disabled:border-pearl-600',
  'disabled:bg-pearl-600',
  'enabled:hover:bg-primary',
  'enabled:hover:text-secondary',
  'disabled:text-pearl-800',
  'enabled:cursor-pointer',
  'disabled:cursor-not-allowed'
];

const selectedClassNames = ['border-primary', 'bg-primary', 'text-secondary'];

type SkuProps = {
  sku: StrapiSkuFragment;
  productId: number;
};

const Sku: React.FC<SkuProps> = ({ productId, sku }) => {
  const selectedSku = useProductSkuSelection(productId);
  const skuId = sku.id as number;
  const selected = selectedSku?.id === skuId;

  if (IS_DEVELOPMENT_ENV && sku.soldBy !== 'weight') {
    throw Error('Unsupported soldBy unit');
  }

  return (
    <button
      disabled={!sku.inStock}
      aria-selected={selected}
      onClick={() => setSkuSelection(productId, sku)}
      className={makeClassNames(
        'flex',
        'transition-colors',
        'w-full',
        'items-center',
        'rounded-md',
        'justify-between',
        'border-2',
        'p-2',
        'px-4',
        'cursor-default',
        selected ? selectedClassNames : unselectedClassNames
      )}
    >
      <div className="flex items-center space-x-3">
        <input
          type="radio"
          name="sku"
          readOnly
          checked={selected}
          value={sku.id || -1}
          className={makeClassNames(
            'h-4',
            'w-4',
            'text-secondary',
            'checked:border-primary-300',
            'focus:ring-primary',
            'enabled:cursor-pointer',
            'disabled:cursor-not-allowed'
          )}
        />
        <div className="flex flex-col items-start">
          <div className="-mt-1 flex items-baseline">
            <InterfaceText size={TextSize.Pica} color="">
              {sku.weight}
            </InterfaceText>

            <InterfaceText
              size={TextSize.LongPrimer}
              color=""
              className="ml-0.5"
            >
              {sku.weight_unit?.name}
            </InterfaceText>
          </div>

          {typeof sku.uses === 'number' && (
            <InterfaceText size={TextSize.Brevier} color="">
              {sku.inStock ? `Up to ${sku.uses} uses` : 'Out of stock'}
            </InterfaceText>
          )}
        </div>
      </div>

      <InterfaceText color="" className="flex flex-col">
        <span className="sr-only">Price </span>
        <FormattedPrice
          color={selected ? 'text-secondary' : undefined}
          priceIntegerWithDecimals={sku.priceInt}
        />
      </InterfaceText>
    </button>
  );
};

type SkuSelectProps = Readonly<{
  productId: number;
  skus: readonly StrapiSkuFragment[];
}>;

const SkuSelect: React.FC<SkuSelectProps> = ({ productId, skus }) => {
  const [skuError, , setSkuError] = useWritable(skuSelectionErrorStore);
  const hasError = skuError.error === SkuSelectionError.NoWeightSelected;
  const singleSku = skus.length === 1 && skus[0].soldBy === 'unique';

  useEffect(() => {
    if (singleSku) {
      setSkuSelection(productId, skus[0]);
    }
  }, []);

  if (singleSku) {
    return null;
  }

  return (
    <fieldset className="border-t border-primary-200 py-rhythm0">
      <div>
        <legend className="flex items-center space-x-2">
          <ScalesIcon className="fill-primary-900" />
          <InterfaceText>Weight</InterfaceText>
          {skus.length > 1 && (
            <InterfaceText
              size={TextSize.LongPrimer}
              bold={hasError}
              className={makeClassNames(
                'block origin-center',
                skuError.notification && 'animate-shake-x'
              )}
              onAnimationEnd={() => {
                /**
                 * The shake animation has ended
                 * but a selection is still needed
                 */
                setSkuError(value => ({
                  error: value.error,
                  notification: false
                }));
              }}
            >
              (To buy, select one)
            </InterfaceText>
          )}
        </legend>
      </div>

      <div className="mt-3 grid grid-cols-[repeat(auto-fill,minmax(0,13rem))] gap-rhythm0">
        {skus.map(sku => (
          <Sku key={sku.id} sku={sku} productId={productId} />
        ))}
      </div>
    </fieldset>
  );
};

export default SkuSelect;
